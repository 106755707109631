import React from 'react';
import PropTypes from "prop-types";

import useWindowDimensions, { deviceSize } from '../components/../common/utils';
import { Link } from 'gatsby';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);

export const DeviceContext = React.createContext();

const DeviceProvider = props => {
    const { width } = useWindowDimensions();
    const isSmallScreen = React.useMemo(() => width < deviceSize.tablet.num, [width]);

    return (
        <DeviceContext.Provider value={isSmallScreen}>
            {props.children}
        </DeviceContext.Provider>
    );
};

DeviceProvider.propTypes = {
    children: PropTypes.array
}

const Element = ({ element }) => (
    <ErrorBoundary>
        <DeviceProvider>
            {element}
        </DeviceProvider>
    </ErrorBoundary>
);

Element.propTypes = {
    element: PropTypes.element
};

export default Element;